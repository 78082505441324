<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <btn-voltar to="/usuarios" />
      </v-col>
    </v-row>

    <usuarios-campos
      :carregando-salvar="carregando"
      :validacao-formulario="validacaoFormulario"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
  import usuarios from '@/api/usuarios'

  export default {

    components: {
      UsuariosCampos: () => import('./UsuariosCampos'),
    },

    data () {
      return {
        carregando: false,
        validacaoFormulario: {},
      }
    },

    methods: {
      async salvar (usuario, grupos) {
        this.carregando = true
        this.validacaoFormulario = {}

        let usuarioInserido = null

        try {
          var formData = new FormData()
          Object.entries(usuario).forEach(([campo, valor]) => {
            formData.append(campo, valor)
          })

          const resposta = await usuarios.inserir(formData)
          usuarioInserido = resposta.data
        } catch (e) {
          if (e.response.status === 422) {
            this.validacaoFormulario = this.$erroApi.validacao(e)
            return
          }

          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
          return
        } finally {
          this.carregando = false
        }

        try {
          this.carregando = true

          await usuarios.atualizarGrupos(usuarioInserido.id, {
            grupos: grupos.join(','),
          })

          this.$router.push('/usuarios', () => this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_adicionar') }))
        } catch (e) {
          this.$router.push('/usuarios', () => this.$snackbar.mostrar({ cor: 'warning', mensagem: 'Usuário inserido, mas grupos não salvos' }))
        } finally {
          this.carregando = false
        }
      },
    },
  }
</script>
